import { Box, Button, Image, Select, Flex, Link, Stack, useDisclosure } from '@chakra-ui/react';
import { LocaleCode, useTranslations } from '@ifixit/i18n';
import { type AddToCartEventType, type CartLineItem } from '@ifixit/shopify-cart-sdk';
import { useAddToCart } from '@ifixit/shopify-cart-sdk/hooks';
import { useEffect, useState, type ElementType } from 'react';
import { useCartDrawer } from '../cart';
import { ProductVariantPrice } from '../commerce';
import type { MerchImageComponent, MerchProductVariant } from './types';
import { useMerchProduct } from './hooks/use-merch-product';

type MerchBannerProps = {
   analytics: { eventType: AddToCartEventType };
   ImageComponent: MerchImageComponent;
   linkComponent?: ElementType;
   localeCode: LocaleCode;
};

const nextFontSizeSm = '14px'; // Bypasses root font size difference between NextJS and Carpenter

export function MerchBanner({ analytics, linkComponent, localeCode }: MerchBannerProps) {
   const { onClose: onCartClose } = useCartDrawer();
   const { onClose: onPopoverClose } = useDisclosure();
   const merch = useMerchProduct();
   const [selectedVariant, setSelectedVariant] = useState<MerchProductVariant | null>(
      merch?.variants[0] ?? null
   );
   useEffect(() => {
      if (merch?.variants) {
         setSelectedVariant(merch.variants[0]);
      }
   }, [merch?.variants]);
   if (merch == null || selectedVariant == null) {
      return null;
   }
   const href = selectedVariant.lineItem.url;

   return (
      <Stack gap={3} bgColor="brand.100" p={3} rounded="lg" position="relative" overflow="hidden">
         <Image
            src={merch.imageSrc}
            boxSize="228px"
            position="absolute"
            left={{ base: '29vw', sm: 'unset' }}
            right={{ sm: '-35px' }}
            top="-28px"
            transform="rotate(25deg)"
            zIndex="0"
         />
         <Box
            display="grid"
            justifyContent="start"
            fontSize={nextFontSizeSm}
            color="brand.500"
            textAlign="left"
            py={{ base: 3, sm: 0 }}
            zIndex={1}
         >
            <Link
               as={linkComponent}
               href={href}
               onClick={onCartClose}
               fontWeight="600"
               mr="auto"
               localeCode={localeCode}
            >
               {merch.header}
               <Box fontWeight="400">{merch.subtext}</Box>
            </Link>
            <ProductVariantPrice
               price={selectedVariant.lineItem.price}
               compareAtPrice={selectedVariant.lineItem.compareAtPrice}
               proPricesByTier={selectedVariant.proPricesByTier}
               showDiscountLabel={false}
               localeCode={localeCode}
               size="small"
               mt={2}
            />
         </Box>
         <Flex gap="2.5" marginRight={{ md: 'auto' }} zIndex={1}>
            <ApparelSizeVariantSelector
               variants={merch.variants}
               selected={selectedVariant}
               setSelected={setSelectedVariant}
            />
            <BuyButton
               analytics={analytics}
               lineItem={selectedVariant.lineItem}
               onClick={onPopoverClose}
               shopifyHandle={merch.handle}
               localeCode={localeCode}
            />
         </Flex>
      </Stack>
   );
}

function ApparelSizeVariantSelector({
   variants,
   selected,
   setSelected,
}: {
   variants: MerchProductVariant[];
   selected: MerchProductVariant;
   setSelected: (_: MerchProductVariant) => void;
}) {
   return (
      <Select
         value={selected.apparelSize}
         onChange={event => {
            const newSelection = variants.find(v => v.apparelSize === event.target.value);
            if (newSelection) {
               setSelected(newSelection);
            }
         }}
         size="sm"
         fontSize={nextFontSizeSm}
         minHeight="32px"
         borderRadius="md"
         textOverflow="ellipsis"
         bgColor="white"
         flex="1"
         minWidth="max-content"
      >
         {variants.map(variant => (
            <option key={variant.apparelSize} value={variant.apparelSize}>
               {variant.apparelSize}
            </option>
         ))}
      </Select>
   );
}

function BuyButton({
   analytics,
   lineItem,
   onClick,
   shopifyHandle,
   localeCode,
}: {
   analytics: { eventType: AddToCartEventType };
   lineItem: CartLineItem;
   onClick: () => void;
   shopifyHandle: string;
   localeCode: string;
}) {
   const { addToCart, enabled } = useAddToCart(analytics);
   const t = useTranslations('CartPage');
   return (
      <Button
         isDisabled={!enabled}
         isLoading={isAddingToCart()}
         onClick={() => {
            addToCart.mutate({
               analytics: {
                  type: 'product',
                  currentItemCode: lineItem.itemcode,
                  handle: shopifyHandle,
                  variantid: lineItem.shopifyVariantId,
                  localeCode,
               },
               lines: [lineItem],
            });
            onClick();
         }}
         size="sm"
         fontSize={nextFontSizeSm}
         variant="cta"
         flex="110px"
         minHeight="32px" // override Chakra default
      >
         {t('addToCart')}
      </Button>
   );

   function isAddingToCart() {
      const isItem =
         addToCart.variables?.analytics.type === 'product' &&
         addToCart.variables.analytics.currentItemCode === lineItem.itemcode;
      return addToCart.isPending && isItem;
   }
}
